<template>
	<v-form :value="isBaseFormValid" @input="updateIsBaseFormValid($event)">
		<v-layout column>
			<w-section :title="$t('workspaces.labels.general_informations')">
				<v-layout v-if="accountingFirmCopy" row wrap>
					<v-flex :pr-1="!isSmallWidth" :xs12="isSmallWidth" :xs6="!isSmallWidth">
						<w-text-input
							v-model="accountingFirmCopy.name"
							:disabled="readonly"
							:label="$t('workspaces.labels.accounting_firm_name')"
							maxlength="191"
							required
						/>
					</v-flex>
					<v-flex
						:class="{
							'mt-1': !accountingFirmCopy.domain,
							'pl-1 sx6': !isSmallWidth,
							xs12: isSmallWidth
						}"
					>
						<w-select
							v-model="accountingFirmCopy.domain"
							:disabled="readonly"
							item-text="url"
							item-value="url"
							:items="domains"
							:label="$t('workspaces.labels.accounting_firm_domain')"
							:loading="domainsLoading"
							required
						/>
					</v-flex>
					<v-flex xs12>
						<v-textarea
							v-model="accountingFirmCopy.welcome_message"
							color="primary"
							:disabled="readonly"
							:label="$t('workspaces.labels.accounting_firm_welcome_message')"
							rows="3"
						/>
					</v-flex>
				</v-layout>
			</w-section>
			<w-section
				v-if="hasWhiteLabelModule && (!accountingFirmCopy || (accountingFirmCopy && !accountingFirmCopy.deleted_at))"
				:title="$t('workspaces.labels.customization')"
			>
				<WhiteLabelForm
					v-model="accountingFirmCopy.white_labels"
					:readonly="readonly"
					:validation="isWhiteLabelValid"
					@update:validation="updateIsWhiteLabelValid"
				/>
			</w-section>
			<w-section v-if="accountingFirmCopy && accountingFirmCopy.id && !accountingFirmCopy.deleted_at" :title="$t('workspaces.labels.ecm_settings')">
				<CatalogTreeStructureForm v-model="accountingFirmCopy.id" />
			</w-section>
		</v-layout>
	</v-form>
</template>

<script>
import WorkspaceModuleGuard from '@/mixins/ModulesGuards/Workspaces/WorkspaceModuleGuard'

export default {
	name: 'AccountingFirmForm',
	components: {
		CatalogTreeStructureForm: () => ({
			component: import('@/components/Workspaces/CatalogTreeStructureForm')
		}),
		WhiteLabelForm: () => ({
			component: import('@/components/WhiteLabel/WhiteLabelForm')
		})
	},
	mixins: [WorkspaceModuleGuard],
	inject: ['holdingContext'],
	props: {
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		validation: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			default: null,
			required: false,
			type: Object
		},
		width: {
			default: null,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			domains: [],
			domainsLoading: false,
			isBaseFormValid: false,
			isWhiteLabelValid: false
		}
	},
	computed: {
		accountingFirmCopy: {
			get: function () {
				return this.value ?? {}
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		hasWhiteLabelModule: function () {
			return this.service.hasModule('customization')
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		isSmallWidth: function () {
			return this.width <= 600
		}
	},
	watch: {
		holdingId: {
			handler: function (holdingId) {
				if (!holdingId) {
					return
				}
				this.loadDomains()
			}
		}
	},
	mounted: function () {
		if (this.holdingId) {
			this.loadDomains()
		}
	},
	methods: {
		loadDomains: function () {
			this.domainsLoading = true

			return this.service
				.listDomains(this.holdingId)
				.then(domains => {
					this.domains = domains
					if (this.domains.length == 1 && !this.accountingFirm?.id) {
						this.accountingFirmCopy.domain = this.domains[0].url
					}
				})
				.finally(() => {
					this.domainsLoading = false
				})
		},
		modifyValidationState: function () {
			this.$emit('update:validation', this.isBaseFormValid && (!this.hasWhiteLabelModule || this.isWhiteLabelValid))
		},
		updateIsBaseFormValid: function (isBaseFormValid) {
			this.isBaseFormValid = isBaseFormValid
			this.modifyValidationState()
		},
		updateIsWhiteLabelValid: function (isWhiteLabelValid) {
			this.isWhiteLabelValid = isWhiteLabelValid
			this.modifyValidationState()
		}
	}
}
</script>